import { default as firstrun814fNx5m9VMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45passwordHpozYSA5DHMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexGZniXIHzfUMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginIo3YPMx7a0Meta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teamFhriEzYYxAMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93s9wOQ5zU54Meta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addPJ8m7S2YLlMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_93I7VUlO317qMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as index1SWIDeQrM1Meta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_93vhGYYj2mQwMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_930MAvPmHbIpMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexQqlaj6h4OhMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendingLuYt5bkd7YMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93iC6cka5QdUMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_93PG1Qv289hfMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexUQ5ba2JQxAMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addVWdBgYXku4Meta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_93IFjl3ORfQAMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as index9dHSS9DuuVMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93Ivk1I7smj0Meta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as addR71xngKsASMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93d1y0HBdtXxMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexb3CJGubgggMeta } from "/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: "firstrun",
    path: "/firstrun",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordHpozYSA5DHMeta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginIo3YPMx7a0Meta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meet-the-team",
    path: "/meet-the-team",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: "move-notifications-index-filter",
    path: "/move-notifications/:filter?",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: "movers-add",
    path: "/movers/add",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: "movers-edit-id",
    path: "/movers/edit/:id()",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "movers",
    path: "/movers",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-add-step",
    path: "/moves/add/:step?",
    meta: _91_91step_93_93vhGYYj2mQwMeta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-edit-id-step",
    path: "/moves/edit/:id()/:step?",
    meta: _91_91step_93_930MAvPmHbIpMeta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves",
    path: "/moves",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-pending",
    path: "/moves/pending",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: "moves-view-id",
    path: "/moves/view/:id()",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "moves-void-id-step",
    path: "/moves/void/:id()/:step?",
    meta: _91_91step_93_93PG1Qv289hfMeta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-void",
    path: "/moves/void",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-add",
    path: "/partner-users/add",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-edit-id",
    path: "/partner-users/edit/:id()",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner-users",
    path: "/partner-users",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93Ivk1I7smj0Meta || {},
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "properties-add",
    path: "/properties/add",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: "properties-edit-id",
    path: "/properties/edit/:id()",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/home/forge/iceman.app.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]